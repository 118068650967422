@import 'variables';

.ds-background {
    background: linear-gradient(to right, $ds-purple 2.45%, $ds-blue 101.9%);
}

.blue-border-top {
    border-top: 1px solid rgb(87, 98, 213);
}

.blue-border-bottom {
    border-bottom: 1px solid rgba(87, 98, 213);
}

.black-border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.background-bottom {
    background: rgba(88, 101, 242);
}

.background-purple {
    background: rgba(84, 59, 239, 1);
}

.background-palegray {
    background: rgba(243, 245, 247, 1);
}

.light-grey-border-top {
    border-top: 1px solid rgba(51, 51, 51, .2);
}

.ds-border-top {
    border: 1px solid rgba(59, 60, 81, 0.1);
}
