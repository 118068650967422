// Height

.h-0 {
    height: 0 !important;
}

// Width

.w-0 {
    width: 0 !important;
}

.w-90 {
    width: 90% !important;
}

.w-80 {
    width: 80% !important;
}

.w-70 {
    width: 70% !important;
}

.w-60 {
    width: 60% !important;
}

.w-40 {
    width: 40% !important;
}

.w-20 {
    width: 20% !important;
}

// Margin end

.me-6 {
    margin-right: $spacer * 3.5;
}

.me-7 {
    margin-right: $spacer * 6;
}


.me-8 {
    margin-right: $spacer * 8.5;
}

// Margin top 

.mt-6 {
    margin-top: $spacer * 3.5 !important;
}

// Padding X Axis 

.px-4_5 { 
    padding-left: $spacer * 2 !important;
    padding-right: $spacer * 2 !important;
}

.px-6 {
    padding-left: $spacer * 3.5 !important;
    padding-right: $spacer * 3.5 !important;
}

.px-7 {
    padding-left: $spacer * 4.5 !important;
    padding-right: $spacer * 4.5 !important;
}

.px-8 {
    padding-left: $spacer * 6.5 !important;
    padding-right: $spacer * 6.5 !important;
}

// Padding Y Axis

.py-4_5 { 
    padding-top: $spacer * 2 !important;
    padding-bottom: $spacer * 2 !important;
}

// Dynamic breakpoints 

@media (max-width: $desktop) {
    .me-8 {
        margin-right: $spacer * 5.5;
    }
}

@media (max-width: $phone) {
    .me-8 {
        margin-right: $spacer * 2.5;
    }
}


