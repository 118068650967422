@import '~bootstrap/scss/bootstrap';
@import 'fonts';
@import 'mdc-styles';
@import 'typography';
@import 'variables';
@import 'colors';
@import 'additional-bootstrap-classes';
@import 'md-overrides';
@import 'mixins';

a {
    text-decoration: none !important;
    color: $white;
}

body {
    font-family: $primaryfont;
    font-size: $regular !important;
    background-color: $palegray;
    overflow: hidden;

    &.mdc-dialog-scroll-lock {
        .simplebar-mask {
            z-index: unset;
        }
    }

    @media (max-width: $desktop) {
        overflow: auto;
    }

    @media (width < $tablet) {
        width: 100vw;
        height: 100dvh;
        position: relative;
    }
}

.page-host {
    .simplebar-content {
        height: 100% !important;
    }

    height: 100vh;
    overflow: auto;
    overflow-y: overlay;

    @media (width < $tablet) {
        @include square(100%);
        position: absolute;
        top: 0;
        left: 0;
    }
}

.router-view-footer {
    background-color: $whitesmoke;
    min-height: 100%;
}

.simplebar-scrollbar::before {
    background-color: rgba(88, 101, 242, .8) !important;
    right: 3px !important;
}

.simplebar-scrollbar.simplebar-hover::before {
    background-color: rgba(88, 101, 242, .9) !important;
}

.simplebar-dragging .simplebar-scrollbar.simplebar-hover::before {
    background-color: rgba(88, 101, 242, 1) !important;
}

.simplebar-scrollbar.simplebar-visible::before {
    opacity: 1 !important;
}

.simplebar-track {
    z-index: 9999 !important;
}

.simplebar-offset {
    -webkit-overflow-scrolling: unset !important;
}

.category-functional-container,
.template-content-separation {
    margin-bottom: 80px;

    @media (max-width: $tablet) {
        margin-bottom: 60px;
    }

    @media (max-width: $phone) {
        margin-bottom: 40px;
    }
}

section[data-simplebar] {
    max-height: 350px;
    overflow: auto;
    overflow-y: overlay;
    width: 100%;

    @media (max-width: $desktop) {
        max-height: 250px;
    }
}

div[data-simplebar] {
    height: 100vh;
    overflow: auto;
    overflow-y: overlay;

    @media (width < $tablet) {
        height: 100%;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.horizontal-divisor {
    height: 4px;
    width: 90px;
    background-color: $ds-purple;
}

.main-content-padding {
    padding-top: 100px;

    @media (max-width: $desktop) {
        padding-top: 60px;
    }

    @media (max-width: $phone) {
        padding-top: 45px;
    }
}

.content-separation {
    margin: 20px 0 20px 0;
}

//This style is applied to avoid blurryness of increase/decrease material icons
.material-icons {
    transform: rotate(0.03deg);
}

.link-styling {
    &:hover {
        text-decoration: underline;
    }
}

.mdc-dialog {
    z-index: 1200;
}

.m-display {
    display: none;
}

.dt-display {
    display: block;
}

@media (max-width: $phone) {
    .m-display {
        display: block;
    }

    .dt-display {
        display: none;
    }
}

::-ms-reveal {
    display: none;
}

.arrow-icon {
    color: $black;
    transition: .3s ease;
}

.switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 20px;
    margin: 0 10px;

    @media (max-width: $desktop) {
        margin: 0 4px 0 8px;
    }

    @media (max-width: $phone) {
        margin: 0 10px 0 12px;
    }
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(204, 204, 204, .67);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -4px;
    background-color: $black;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: rgba(67, 0, 169, .54);
}

input:focus + .slider {
    box-shadow: 0 0 1px rgba(67, 0, 169, .54);
}

input:checked + .slider:before {
    background-color: $ds-purple;
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

:not(.mdc-list-item--disabled).mdc-list-item:hover .mdc-list-item__ripple::before,
:not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-surface--hover .mdc-list-item__ripple::before {
    opacity: 0;
}

.ds-border-blue {
    border: 1px solid rgba(76, 96, 133, 1);
}

.gray-border-bottom {
    border-bottom: 1px solid rgba(59, 60, 81, 0.2);
}

.darkblue-border-bottom {
    box-shadow: 0 17px 0 -16px rgba(76, 96, 133, 0.378);
}

.text-shadow {
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.ds-color {
    color: rgba(87, 98, 213);
}

.drawer {
    background-color: $menu-purple;
    color: $white;
    top: 0;
    width: 260px;
    z-index: 100;

    .menu-container {
        border-bottom: 1px solid $primaryborder;
        cursor: pointer;

        .menu-button {
            font-size: $regular;
            text-transform: capitalize;
        }
    }

    .drawer-button-deep {
        background-color: $primarybackground !important;
    }

    .drawer-button {
        border-bottom: 2px solid $primarybackground;
        height: 50px;

        a {
            outline: 0;
        }
    }

    ::-webkit-scrollbar {
        width: 2px;
      }
}

.faq-arrow-icon {
    border: 0;
    cursor: pointer;
    height: 7px;
    transition: 0.3s ease;
    width: 10px;
}

.button-text-disabled {
    cursor: default;
    opacity: 0.2;
}

.opacity-20 {
    opacity: 0.2;
}

.ds-google-pay-box {
    height: 50px;

    button {
        border: none !important;
        color: $white !important;
        background: rgba(84, 59, 239, 1) !important;
        font-family: $primaryfont !important;
        border-radius: 4px !important;
        font-size: $medium !important;
        max-width: 254px !important;
        letter-spacing: .15px !important;
        will-change: transform, opacity !important;
        min-width: unset !important;

        @media (max-width: $widescreen) {
            max-width: unset !important;
        }

        @media (max-width: calc($phone - 1px)) {
            border-radius: 10px !important;
        }
    }
}

/*
    Apple Pay Button
*/
apple-pay-button {
    --apple-pay-button-width: 254px;
    --apple-pay-button-height: 50px;
    --apple-pay-button-border-radius: 4px;
    --apple-pay-button-padding: 8px 0px;
    --apple-pay-button-box-sizing: border-box;
    max-width: 254px;
    width: 100%;
    min-width: unset !important;

    @media (width <= $widescreen) {
        max-width: unset !important;
    }

    @include media-exclusive($phone, true) {
        border-radius: 10px !important;
    }
}

.disabled-gpay-button button,
.disabled-apple-pay-button apple-pay-button {
    opacity: .2;
    pointer-events: none;
}

.textbtn-contact {
    color: $text-btncontact;
    font-size: $largeplus;
    line-height: 30px;

    @media (max-width: $desktop) {
        font-size: $large;
        line-height: 27px;
    }

    @media (max-width: $phone) {
        font-size: $small;
        line-height: 24px;
    }
}

.admin-view-size {
    font-size: $tiny !important;
}

.global-arrow-icon {
    border: 0;
    cursor: pointer;
    transition: 0.3s ease;
}

mdc-select {
    &.mdc-select--activated .global-arrow-icon {
        transform: rotate(180deg) !important;
    }

    .global-arrow-icon {
        transform: rotate(0deg) !important;
    }
}

.arrow-position-below {
    margin-top: -5px;

    &::after {
        top: 100% !important;
        transform: rotate(180deg) !important;
    }
}

.arrow-position-start::after {
    left: 90%;
}

.arrow-position-end::after {
    left: 10%;
}

.mdc-expandable--closed .mdc-expandable__content-container {
    height: 0 !important;
    overflow: hidden !important;
}

.template-border-bottom-line {
    padding-bottom: $hugePad;

    @media (width <= $tablet) {
        padding-bottom: $largePad;
    }

    @media (width <= $phone) {
        padding-bottom: $bigPad;
    }
    
    border-bottom: 2px solid $primaryborder;
}

.default-anchor {
    @include default-anchor();
}

.page-host {
    @include flex-column();
}

.page-host-content {
    flex: 1;
    @include flex(stretch, center);

    > * {
        width: 100%;
    }
}

#intercom-facade-btn {
    background-color: $lilac-alternative !important;
}
