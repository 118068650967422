@use 'styles/variables' as v;

@mixin padding-y($val, $half: false, $important: false) {
    @if $half {
        $val: calc($val / 2);
    }

    $val: get-important-val($val, $important);
    padding-top: $val;
    padding-bottom: $val;
}

@mixin padding-x($val, $half: false, $important: false) {
    @if $half {
        $val: calc($val / 2);
    }

    $val: get-important-val($val, $important);
    padding-left: $val;
    padding-right: $val;
}

@mixin margin-y($val) {
    margin-top: $val;
    margin-bottom: $val;
}

@mixin margin-x($val) {
    margin-left: $val;
    margin-right: $val;
}

@mixin flex($align: initial, $justify: initial, $direction: row, $important: false) {
    display: get-important-val(flex, $important);
    align-items: $align;
    justify-content: $justify;
    flex-direction: $direction;
}

@mixin flex-column($align: initial, $justify: initial) {
    @include flex($align, $justify, column);
}

@mixin flex-center($direction: row) {
    @include flex(center, center, $direction);
}

@mixin column-gap($gap, $align: initial, $justify: initial) {
    @include flex-gap($gap, $align, $justify, column);
}

@mixin flex-gap($gap, $align: initial, $justify: initial, $direction: row) {
    @include flex($align, $justify, $direction);
    gap: $gap;
}

@mixin flex-basis($width) {
    flex-basis: $width;
    max-width: $width;
}

@mixin square($value, $important: false) {
    $value: get-important-val($value, $important);
    height: $value;
    width: $value;
}

@mixin square-padding($value, $padding) {
    @include square($value + ($padding * 2));
    padding: $padding;
}

@mixin circle($value, $important: false) {
    @include square($value, $important);
    border-radius: 100%;
}

@mixin full-size() {
    @include square(100%);
}

@mixin container-fluid($max: 100%, $important: false, $min: 0, $width: 100%) {
    width: get-important-val($width, $important);
    min-width: get-important-val($min, $important);
    max-width: get-important-val($max, $important);
}

@mixin no-spacing($important: false) {
    $val: get-important-val(0, $important);
    padding: $val;
    margin: $val;
}

@mixin media($val, $max: true, $offset: 0px) {
    @if $max {
        @media (width <=calc($val + $offset)) {
            @content;
        }
    }

    @else {
        @media (calc($val + $offset) <=width) {
            @content;
        }
    }
}

@mixin contentUrl($url){
content: url($url);
}

@mixin contentGreenIcon($iconName) {
    @include contentUrl("/icons/#{$iconName}-icon-green.svg")
}

@mixin border-radius-left($size) {
    border-top-left-radius: $size;
    border-bottom-left-radius: $size;
}

@mixin border-radius-right($size) {
    border-top-right-radius: $size;
    border-bottom-right-radius: $size;
}

@mixin border-radius-x($size) {
    border-top-right-radius: $size;
    border-top-left-radius: $size;
}

@mixin border-radius-bottom($size) {
    border-bottom-left-radius: $size;
    border-bottom-right-radius: $size;
}

@mixin media-exclusive($val, $max: true) {
    $offset: 1px;

    @if $max {
        $offset: -1px;
    }

    @include media($val, $max, $offset) {
        @content;
    }
}

@mixin media-range($min, $max) {
    @media (calc($min + 1px) <=width <=$max) {
        @content;
    }
}

@mixin truncate-text($lines, $break: false) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @if $break {
        word-break: break-all;
    }
}

@mixin grid($columns: false, $spaces: 1, $separation: 1fr) {
    display: grid;

    @if ($columns) {
        grid-template-columns: repeat($spaces, $separation);
    }

    @else {
        grid-template-rows: repeat($spaces, $separation);
    }
}

@mixin grid-square($gap, $columns: false, $spaces: 1, $separation: 1fr) {
    @include grid($columns, $spaces, $separation);
    gap: $gap;
}

@mixin grid-gap($gap, $vertical: false, $columns: false, $spaces: 1, $separation: 1fr) {
    @include grid($columns, $spaces, $separation);

    @if ($vertical) {
        row-gap: $gap;
    }

    @else {
        column-gap: $gap;
    }
}

@mixin fade($spacing, $color1: transparent, $color2: rgb(48, 49, 65), $height: 50px) {
    position: relative;

    &:not(.touching-bottom)::after,
    &:not(.touching-top)::before {
        @include padding-x($spacing);
        content: "";
        height: $height;
        width: 100%;
        left: 0;
        position: absolute;
        display: block;
        z-index: 9;
    }

    &:not(.touching-bottom)::after {
        bottom: -1px;
        background: linear-gradient(180deg, $color1 0%, $color2 100%);
    }

    &:not(.touching-top)::before {
        top: -1px;
        background: linear-gradient(180deg, $color2 0%, $color1 100%);
    }
}

@mixin showOnHover($elements, $transition: false, $display: block) {
    @each $element in $elements {
        #{$element} {
            @if $transition {
                opacity: 0;
            }

            @else {
                display: none;
            }
        }

        &:hover {
            #{$element} {
                @if $transition {
                    opacity: 100;
                }

                @else {
                    display: $display;
                }

            }
        }

        @if $transition {
            #{$element} {
                transition: opacity $transition;
            }
        }
    }

    &:hover {
        @content;
    }
}

@mixin container-settings($size, $bottom, $sides, $section) {
    @media (width <=$size) {
        &:has(#{$section}) {
            padding-bottom: $bottom;

            .container-fluid {
                padding-left: $sides;
                padding-right: $sides;
            }
        }
    }
}

@mixin makeAnimation($name, $duration, $args...) {
    animation: $name $duration $args;

    @keyframes #{$name} {
        100% {
            @content;
        }
    }
}

@mixin absoluteCenter($horizontal: true, $vertical: true) {
    position: absolute;

    @if ($vertical) {
        top: 0;
        bottom: 0;
    }

    @if ($horizontal) {
        left: 0;
        right: 0;
    }

    margin: auto;
}

@mixin absoluteOffset($size, $top: true, $right: true) {
    position: absolute;
    @include getPositions($size, $top, $right)
}

@mixin fixedOffset($size, $top: true, $right: true) {
    position: fixed;
    @include getPositions($size, $top, $right);
}

@mixin getPositions($size, $top: true, $right: true, $important: false) {
    $val: get-important-val($size, $important);

    @if $top {
        top: $val;
    }

    @else {
        bottom: $val;
    }

    @if $right {
        right: $val;
    }

    @else {
        left: $val;
    }
}

@mixin optionIcon($size: 24px, $color: rgb(87, 98, 213), $padding : null) {
    @if $padding {
        @include square-padding($size, $padding);
    }

    @else {
        @include square($size);
    }

    @include flex-center();
    font-size: $size;
    color: $color;

    &--white {
        color: white;
        filter: brightness(0) invert(1);
    }
}

@mixin font($size: null, $weight: null, $height: null, $spacing: null, $color: null, $opacity: null, $important: false) {
    $imp: if($important, '!important', '');
    line-height: $height #{$imp};
    font-size: $size #{$imp};
    font-weight: $weight #{$imp};
    letter-spacing: $spacing #{$imp};
    color: $color #{$imp};
    opacity: $opacity #{$imp};
}

@mixin centerContainer($containerSize, $important: false) {
    $val: get-important-val(calc((100% - #{$containerSize}) / 2), $important);
    position: absolute;
    top: $val;
}

@mixin disable-select($important: false) {
    $val: get-important-val(none, $important);
    -webkit-user-select: $val;
    -ms-user-select: $val;
    user-select: $val;
}

@mixin default-anchor() {
    a {
        color: v.$sign-up-blue;
        cursor: pointer;

        @include ul-hover();
    }
}

@mixin line-separators-items($selector) {
    #{$selector} {
        position: relative;
    }

    #{$selector}:not(:last-child)::after {
        content: "";
        position: absolute;
        width: calc(100% - 30px);
        height: 1px;
        bottom: 0px;
        background-color: rgba($white, 0.2);
        z-index: 1;
        left: 15px;
    }
}

@mixin skeleton($background-color: v.$primarybackground, $loading-color: v.$offblack-hover, $timing: 1s) {
    background-color: $background-color;

    @include makeAnimation(skeleton-loading, $timing, linear infinite alternate) {
        background-color: $loading-color;
    }
}

@mixin border-y($size, $color, $important: false) {
    $val: get-important-val($size solid $color, $important);
    border-top: $val;
    border-bottom: $val;
}

@mixin border-x($size, $color, $important: false) {
    $val: get-important-val($size solid $color, $important);
    border-left: $val;
    border-right: $val;
}

@mixin ul-hover($important: false) {
    &:hover {
        text-decoration: get-important-val(underline, $important);
    }
}

@mixin size($width, $height, $important: false) {
    width: get-important-val($width, $important);
    height: get-important-val($height, $important);
}

@mixin size-flex($width, $height, $justify: flex-start, $align: flex-start, $direction: row, $important: false) {
    @include size($width, $height, $important);
    @include flex($align, $justify, $direction, $important);
}

@mixin size-flex-center($width, $height, $direction: row, $important: false) {
    @include size($width, $height, $important);
    @include flex-center($direction);
}

@mixin square-flex($size, $justify: flex-start, $align: flex-start, $direction: row, $important: false) {
    @include square($size, $important);
    @include flex($align, $justify, $direction, $important);
}

@mixin square-flex-center($size, $direction: row, $important: false) {
    @include square($size, $important);
    @include flex-center($direction);
}

@mixin flip-animation() {
    transform: scale(-1, -1);
}

@mixin box-default() {
    @include flex-column(initial, center);
    background-color: v.$offblack;
    padding: 8px v.$regularPad;
    border-radius: 4px;
}

@mixin customerPortalStyle($radius: 4px) {
    background: v.$secondarybackground;
    border-radius: $radius;
    box-shadow: 0 15px 15px -2px rgb(51, 51, 75);
}

@mixin search-btn-orders($media: $phone) {
    &:hover {
        background-color: $hoverblue;
    }

    @if $media {
        display: none;

        @include media($media) {
            @include search-btn-orders-inner();
        }
    }

    @else {
        @include search-btn-orders-inner();
    }

    .search-icon {
        margin-left: 8px;
    }
}

@mixin search-btn-orders-inner() {
    @include flex-center();
    text-align: center;
    background-color: $blue;
    border-radius: 0.25rem;
    font-size: $regular;
    width: 75px;
    height: 50px;
    line-height: 1rem;
    font-weight: 500;
    letter-spacing: 0.448px;
}

@mixin game-background($url: '/customer-portal/background.jpg') {
    background-image: url($url);
    background-position: top;
    background-repeat: no-repeat;
    background-size: 100vw auto;
}

@mixin has-any($values) {
    $selector-list: (
    );

@each $value in $values {
    $selector-list: append($selector-list, ":has(#{$value}),");
}

&#{$selector-list} {
    @content;
}
}

@mixin media-has-any($resolution, $values) {
    @include media($resolution) {
        @include has-any($values) {
            @content;
        }
    }
}

@mixin regular-border($hide: true, $resolution: v.$desktop) {
    border-radius: 4px;
    outline: v.$regular-border;
    outline-offset: -1px;

    @if ($hide) {
        @include media($resolution) {
            outline: unset;
        }
    }
}

@mixin regular-container-paddings($offset: 0px) {
    padding-block: v.$hugePad;

    @include media(calc(v.$tablet + $offset)) {
        padding-block: v.$largePad;
    }

    @include media(calc(v.$phone + $offset)) {
        padding-block: v.$bigPad;
    }
}

// Functions 

@function get-important-val($value, $important) {
    @return $value if($important, !important, null);
}