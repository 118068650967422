@import "variables";

.small-letter-spacing {
    letter-spacing: 0.08px;
}

.letter-spacing {
    letter-spacing: 0.133px;
}

.letter-spacing-basic {
    letter-spacing: 1.28px;
}

.letter-spacing-small {
    letter-spacing: 0.152px;
}

.letter-spacing-medium {
    letter-spacing: 0.52px;
}

.letter-opacity {
    opacity: 0.75;
}

.light-opacity {
    opacity: 0.5;
}

.secondary-font {
    font-family: $secondaryfont;
}

.text-small {
    font-size: $small;
}

.text-extra-tiny {
    font-size: $extra-tiny;
}

.text-tiny {
    font-size: $tiny;
}

.text-small-plus {
    font-size: $smallplus;
}

.text-regular {
    font-size: $regular;
    letter-spacing: 0.51px;
    color: $lightgray;
}

.text-normal {
    font-size: $regular;
}

.text-regular-plus {
    font-size: $regularplus;
}

.text-medium {
    font-size: $medium;
}

.text-large {
    font-size: $large;
}

.text-large-plus {
    font-size: $largeplus;
}

.text-large-plus-nomq {
    font-size: $largeplus;
}

.text-extra-large {
    font-size: $extralarge;
}

.text-huge {
    font-size: $huge;
}

.text-extra-huge {
    font-size: $extrahuge;
}

.text-extra-huge-title {
    font-size: $extrahugetitle;
}

.text-extra-huge-plus {
    font-size: $extrahugeplus;
}

.text-extra-extra-huge {
    font-size: $extraextrahuge;
}

.text-home-card-huge {
    font-size: $homecardhuge;
}

@media (max-width: $desktop) {
    .text-extra-huge {
        font-size: $morehuge;
    }

    .text-extra-large {
        font-size: $large;
    }

    .text-large-plus {
        font-size: $medium;
    }

    .text-home-card-huge {
        font-size: $largeplusplus;
    }
}

@media (max-width: $phone) {
    .text-small-plus {
        font-size: $small;
    }

    .text-regular {
        font-size: $small;
    }

    .text-normal {
        font-size: $small;
    }

    .text-medium {
        font-size: $small;
    }

    .text-huge {
        font-size: $largeplusplus;
    }

    .text-home-card-huge {
        font-size: $homecardhuge;
    }

    .text-extra-huge {
        font-size: $extralargeplus;
    }

    .text-extra-huge-plus {
        font-size: $hugeplus;
    }

    .text-extra-extra-huge {
        font-size: $extrahugeplus;
    }
}

.text-black {
    color: $black;
}

.text-white {
    color: $white;
}

.text-red {
    color: $red;
}

.text-blue {
    color: $blue;
}

.text-footer-link {
    color: $footer-link-gray;
}

.dark-purple {
    color: $darkpurple;
}

.border-line {
    color: $grayborder;
}

.text-green {
    color: $green;
}

.text-red {
    color: $red;
}

.text-ds-purple {
    color: $ds-purple;
}

.text-ds-signup-blue {
    color: $sign-up-blue;
}

.text-purple {
    color: $purple-text;
}

.text-success-green {
    color: $success-green;
}

.text-second-success-green {
    color: $second-success-green;
}

.text-green-ds {
    color: $ds-mediumgreen;
}

.text-darkergray {
    color: $darkergray !important;
}

.text-dark-blue {
    color: $menu-purple !important;
}

.ds-light-purple {
    color: $ds-footer-link;
}

.text-input-placeholder {
    color: $input-placeholder;
}

.text-jetgray {
    color: $jetgray;
}

.text-settings-title {
    color: $settings-title;
}

.mid-gray {
    color: $midgray;
}

.text-offblack {
    color: $offblack;
}

.text-purple {
    color: $lightpurple;
}

.text-gray {
    color: $ds-home;
}

.green-text {
    color: $ds-green;
}

.text-yellow {
    color: $dark-yellow;
}

.lightgray-text {
    color: $dstext-gray;
}

.purple-ds {
    color: $ds-home-button;
}

.gray-ds-text {
    color: $dsgray-text;
}

.divisor-gray {
    color: $divisor-gray;
}

.divisor-title {
    background-color: $ds-home-button;
}

.font-italic {
    font-style: italic;
}

.font-light {
    font-weight: 300;
}

.font-regular {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.no-decoration {
    text-decoration: none;
}
