@use '@material/button';
@use '@material/linear-progress';
@use '@material/textfield';
@use '@material/select';
@use '@material/checkbox';
@use '@material/textfield/icon';
@use '@material/radio';
@use '@material/slider';
@use '@material/tooltip';
@use '@material/dialog';
@use '@material/dialog/mdc-dialog';
@import 'variables';

$offwhite: rgba(255, 255, 255, 0.6);
$offgreen: rgba(57, 226, 157, 0.7);
$offred: rgba(253, 74, 74, 0.75);
$offgreen-disabled: rgba(57, 226, 157, 0.2);
$offred-disabled: rgba(253, 74, 74, 0.2);
$offblue-disabled: rgba(87, 98, 213, .2);
$hoverblue: rgba(74, 83, 181, 1);
$lightgray: rgba(76, 96, 133, 0.2);

body {
    --mdc-theme-primary: rgba(87, 98, 213, 1);
    --mdc-theme-secondary: rgba(76, 96, 133, 1);
    --mdc-theme-on-primary: $primarycolor;
    --mdc-typography-font-family: $primaryfont;
    --mdc-background-primary: rgba(0, 0, 0, 0);
}

.mdc-select {
    .mdc-menu-surface {
        margin-top: 1px;
    }
}

.mdc-menu-surface {
    overflow: hidden;
}

:not(.mdc-list-item--disabled).mdc-list-item--selected .mdc-list-item__ripple:before {
    background-color: unset;
}

.mdc-list {
    padding: 0;
}

.mdc-list-item__primary-text {
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    letter-spacing: .68px;
}

.mdc-list-item--selected .mdc-list-item__primary-text {
    color: rgba(255, 255, 255, 1);
}

.mdc-button {
    height: 50px;
}

.mdc-text-field--filled {
    height: 50px;
}

.mdc-select__anchor {
    border-radius: 4px;
}

.mdc-select--outlined .mdc-select__anchor {
    height: 50px;
}

.mdc-select--filled .mdc-select__anchor {
    height: 50px;
}

.mdc-text-field--outlined:not(.mdc-text-field--textarea) {
    height: 50px;
}

.mdc-select .mdc-floating-label {
    top: 55%;
}

.select-background-outlined {
    @include select.label-color(('default': $ds-home, 'hover': $ds-home, 'focus': $ds-home));
    @include select.ink-color($ds-home);

    .mdc-select--disabled {
        background-color: $slateblue-off-low-opacity;
        @include select.ink-color((disabled: $blue));
    }

    .mdc-ripple-surface:hover::before,
    .mdc-ripple-surface:hover .mdc-list-item__ripple::before {
        background: rgba(84, 59, 239, 1);
        opacity: 0.2;
    }

    .mdc-ripple-upgraded.mdc-ripple-upgraded--background-focused::before {
        background: rgba(84, 59, 239, 1);
        opacity: 0.1;
    }

    .mdc-menu-surface::-webkit-scrollbar-thumb {
        background-color: $blue;
    }
}

.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--invalid:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
    border-color: unset !important;
}

.mdc-floating-label--required::after {
    content: "";
}

.mdc-floating-label {
    font-size: 16px !important;
    top: 50%;
    transform: translateY(-50%) !important;
}

.mdc-button {
    text-transform: unset;
}

.button-mobile {
    @include button.filled-accessible($sign-up-blue);
    @include button.ink-color($white);
    @include button.disabled-container-fill-color($sign-up-blue);
    @include button.disabled-ink-color($white);
}

.button-mobile:hover {
    @include button.filled-accessible($hoverblue);
}

.button-mobile:disabled {
    opacity: 0.2;
}

.button-green-filled {
    @include button.filled-accessible(rgba(0, 180, 105, 1));
    @include button.ink-color($white);
    letter-spacing: 0.15px;
    text-align: center;
}

.button-purple-filled {
    @include button.filled-accessible(rgba(84, 59, 239, 1));
    @include button.ink-color($white);
    @include button.disabled-container-fill-color(rgba(84, 59, 239, 1));
    @include button.disabled-ink-color($white);
    appearance: none;
    letter-spacing: 0.15px;
    text-align: center;
    -webkit-appearance: none;
}

.button-darkpurple-filled {
    @include button.filled-accessible($sign-up-blue);
    @include button.ink-color($white);
    @include button.disabled-container-fill-color($sign-up-blue);
    @include button.disabled-ink-color($white);
    appearance: none;
    letter-spacing: 0.15px;
    text-align: center;
    -webkit-appearance: none;
}

.button-purple-filled:disabled {
    opacity: 0.2;
    cursor: default;
}

.button-green-low-transparency {
    @include button.filled-accessible(rgba(57, 226, 157, .1));
    @include button.outline-color($transparent);
    @include button.outline-width(0);
    @include button.ink-color($green);
    @include button.container-fill-color(('disabled': rgba(57, 226, 157, .05)));
    @include button.disabled-ink-color($offgreen-disabled);
    letter-spacing: 0.15px;
    text-align: center;
}

.button-green-low-transparency:hover {
    @include button.filled-accessible(rgba(57, 226, 157, .2));
    @include button.ink-color($green);
}

.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__notch,
.mdc-select--outlined.mdc-select--disabled .mdc-notched-outline__trailing {
    border: 0;
}

.input-profile {
    @include textfield.fill-color($inputgray);
    @include textfield.ink-color($black);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($lightgray);
    @include textfield.label-color($white);
    @include textfield.disabled-ink-color(rgba(114, 118, 125, 0.75));
    @include textfield.disabled-fill-color(rgba(76, 96, 133, 0.2));
    @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(87, 98, 213, 1));
    height: 35px !important;
    font-size: $small !important;
    border-radius: 6px;
    border-top: 2px solid $success-green;
}

.input-ticket-title {
    @include textfield.ink-color($black);
    @include textfield.fill-color($transparent);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color($transparent);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include textfield.caret-color($purple-text);
}

.input-password {
    @include textfield.fill-color($palegray);
    @include textfield.ink-color($black);
    @include textfield.outline-color($transparent);
    @include textfield.focused-outline-color($transparent);
    @include textfield.hover-outline-color($transparent);
    @include icon.trailing-icon-color($lightgray);
    @include textfield.label-color($white);
    @include textfield.disabled-ink-color(rgba(114, 118, 125, 0.75));
    @include textfield.disabled-fill-color($palegray);
    @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color(rgba(87, 98, 213, 1));
    border: 0.5px;
    border-radius: 4px;
}

.input-password_warning {
    @include textfield.disabled-ink-color(rgba(227, 171, 8, 1));
}

.input-otp {
    @include textfield.fill-color($inputgray);
    @include textfield.ink-color($mediumblack);
    @include textfield.focused-outline-color($ds-purple);
    @include textfield.outline-color(rgba(213, 213, 213, 1));
    @include textfield.bottom-line-color($lilac);
    @include textfield.hover-bottom-line-color($lilac);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color($lilac);
    border-radius: 4px;
    height: 60px !important;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .mdc-text-field__input {
        font-size: $extralarge !important;
        font-weight: 700 !important;
        width: 18px !important;
    }

    @media (max-width: $phone) {
        height: 53px !important;

        .mdc-text-field__input {
            font-size: $largeplus !important;
            width: 10px !important;
        }
    }
}

.input-otp__error {
    @include textfield.fill-color($inputgray);
    @include textfield.ink-color($mediumblack);
    @include textfield.focused-outline-color($ds-purple);
    @include textfield.outline-color($off-red);
    @include textfield.bottom-line-color($red);
    @include textfield.hover-bottom-line-color($red);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color($lilac);
    border-radius: 4px;
    height: 60px !important;
}

.input-otp__success {
    @include textfield.fill-color($inputgray);
    @include textfield.ink-color($mediumblack);
    @include textfield.focused-outline-color($ds-purple);
    @include textfield.outline-color($green);
    @include textfield.bottom-line-color($success-green);
    @include textfield.hover-bottom-line-color($success-green);
    @include textfield.disabled-outline-color($transparent);
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.placeholder-color($input-placeholder);
    @include textfield.prefix-color($white);
    @include textfield.suffix-color($white);
    @include textfield.caret-color($lilac);
    border-radius: 4px;
    height: 60px !important;
}

.input-background {
    @include textfield.disabled-fill-color(rgba(76, 96, 133, 0.1));
    @include textfield.fill-color($lightgray);
    @include textfield.outline-color($lightgray);
    @include textfield.focused-outline-color(rgba(255, 255, 255, 0.6));
    @include textfield.hover-outline-color(rgba(255, 255, 255, 0.6));
    @include textfield.ink-color($dstext-gray);
    @include textfield.label-color($dstext-gray);
    @include textfield.placeholder-color($dstext-gray);
    @include textfield.disabled-ink-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-label-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-prefix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-suffix-color(rgba(255, 255, 255, 0.35));
    @include textfield.disabled-outline-color(rgba(255, 255, 255, 0.55));
    @include textfield.disabled-bottom-line-color(transparent);
    @include textfield.bottom-line-color(transparent);
    @include textfield.line-ripple-color(transparent);
    @include textfield.hover-bottom-line-color(transparent);
    @include textfield.disabled-placeholder-color(rgba(255, 255, 255, 0.3));
    @include textfield.suffix-color($dstext-gray);
    @include textfield.caret-color(rgba(87, 98, 213, 1));
    border-radius: 4px;
}

.select-background {
    @include select.container-fill-color($palegray);
    @include select.label-color(('default': $black,
            'hover': $black,
            'focus': $black));
    @include select.ink-color($black);
    @include select.dropdown-icon-color(('default': $black, 'hover': $black, 'focus': $black));
    @include select.outline-color(('default': $transparent,
            'hover': $transparent,
            'focus': $transparent));
}

.checkbox {
    @include checkbox.container-colors($unmarked-stroke-color: $lightgray,
        $unmarked-fill-color: $transparent,
        $marked-stroke-color: $lightgray,
        $marked-fill-color: $palegray,
        $generate-keyframes: false);
    @include checkbox.disabled-container-colors($unmarked-fill-color: rgba(39, 44, 57, 1));
    @include checkbox.ink-color($white);
    @include checkbox.focus-indicator-color($transparent);
    @include checkbox.disabled-ink-color($ds-purple);

    &:hover {
        .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
            background-color: $lightgray;
        }
    }

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        border-color: $transparent !important;
        background-color: $ds-purple !important;
    }

    .mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background,
    .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
    .mdc-checkbox__native-control:enabled:not(:checked)~.mdc-checkbox__background {
        animation-duration: unset;
        animation-timing-function: unset;
    }

    .mdc-checkbox__ripple:after,
    .mdc-checkbox__ripple:before {
        background-color: unset;
    }

    @media (max-width: $desktop) {
        @include checkbox.container-colors($unmarked-stroke-color: $gray,
            $marked-stroke-color: $lightgray,
            $unmarked-fill-color: $white,
            $marked-fill-color: $lightgray);
        @include checkbox.ink-color($green);

        .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
            border-color: $transparent !important;
            background-color: $palegray !important;
        }
    }
}

.customer-checkbox {
    @include checkbox.ink-color($white);

    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
        border-color: $transparent !important;
        background-color: $ds-purple !important;
    }
}

.mdc-select .mdc-floating-label {
    font-size: 14px !important;

    @media (max-width: $desktop) {
        font-size: 12px !important;
    }
}

.mdc-dialog {
    @include dialog.container-fill-color($white);
    @include dialog.title-ink-color($black);
    @include dialog.content-ink-color($softblack);
    @include dialog.shape-radius(12px, false);

    .mdc-dialog__title {
        font-size: $extralarge;
        text-align: center;
    }

    .mdc-dialog__content {
        text-align: center;
    }
}

.mdc-tooltip.mdc-tooltip--shown::after {
    opacity: 1;
}

.mdc-tooltip.mdc-tooltip--hide {
    display: none;
}

.mdc-tooltip {
    @include tooltip.fill-color($sign-up-blue);
    @include tooltip.label-ink-color($white);
    border-radius: 4px;
    margin-top: 5px;
    box-shadow: unset;
    max-width: 250px;

    .mdc-tooltip__surface {
        min-height: 32px;
        font-size: 12px;
        font-weight: 300;
        padding: 10px;
        transform: scale(1) !important;
    }
}

.mdc-tooltip::after {
    border-color: $transparent $transparent $primaryborder;
    border-style: solid;
    border-width: 5px;
    bottom: 100%;
    content: ' ';
    left: 50%;
    margin-left: -5px;
    position: absolute;
    opacity: 0;
    transition: opacity 75ms cubic-bezier(.4, 0, 1, 1) 0ms;
}

.mdc-button--touch {
    margin-block: 0;
}

.mdc-touch-target-wrapper {
    .mdc-button {
        min-width: unset;
        padding: unset;

        &__ripple:after,
        &__ripple:before {
            background-color: unset !important;
        }
    }
    
    .mdc-button--touch {
        margin-block: 0;
        height: initial;
    }
}

.ds-form-control {
    border: 0;
    border-radius: 12px;
    background-color: $backgroundcontrol !important;    
    @include textfield.focused-outline-color($purple-text);
    @include textfield.hover-outline-color($purplehover);
    @include textfield.outline-color($transparent);
    @include textfield.ink-color($input-placeholder);
    @include textfield.outline-shape-radius(12px);

    &:hover {
        @include textfield.ink-color($input-placeholder-hover);        
    }

    &.mdc-text-field--focused {
        @include textfield.ink-color($black);
        box-shadow: none;
    }

    &.ds-form-control--error {
        @include textfield.focused-outline-color($red);
        @include textfield.hover-outline-color($red-hover);
        @include textfield.outline-color($transparent);
    }

    &.ds-form-control--success { 
        @include textfield.focused-outline-color($success-green);
        @include textfield.hover-outline-color($success-green-hover);
        @include textfield.outline-color($transparent);
    }

    &.ds-form-control--warning { 
        @include textfield.focused-outline-color($yellow);
        @include textfield.hover-outline-color($yellow-hover);
        @include textfield.outline-color($transparent);
    }

    &.mdc-text-field--disabled {            
        &:hover {
            outline: 1px solid $purplehover;                
        }
    }

    &.ds-form-control--warning {
        &.mdc-text-field--disabled {                
            &:hover {
                outline: 1px solid $yellow-hover;                
            }

            &.mdc-ripple-upgraded--foreground-activation {
                outline: 1px solid $yellow;
            }
        }
    }

    &.ds-form-control--success {
        &.mdc-text-field--disabled {                
            &:hover {
                outline: 1px solid $success-green-hover;                
            }
        }
    }

    &.ds-form-control--error {
        &.mdc-text-field--disabled {                
            &:hover {
                outline: 1px solid $red-hover;                
            }
        }
    }

    input:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $inputgray inset;
        -webkit-box-shadow: 0 0 0px 1000px $inputgray inset;
    }
}

.select-blue-filled {
    --mdc-theme-surface: rgba(243, 245, 247);
    --mdc-theme-on-surface: $menu-purple;
    @include select.container-fill-color(('default': $palegray, 'disabled': $palegray));
    @include select.label-color(('default': $menu-purple, 'hover': $menu-purple, 'focus': $menu-purple));
    @include select.ink-color(('default': $menu-purple, 'disabled': $menu-purple));
    @include select.dropdown-icon-color(('default': $sign-up-blue, 'hover': $sign-up-blue, 'focus': $sign-up-blue));
    @include select.label-floating-color($palegray);
    @include select.outline-color(('default': $transparent, 'hover': $transparent, 'focus': $transparent));

    &.mdc-select--activated .mdc-select__anchor {
        background-color: rgb(247, 247, 247) !important;
    }
}

.select-blue-filled:hover {
    @include select.container-fill-color(rgb(240, 241, 241));
}
